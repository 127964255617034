import { DrawerContent, DrawerTitle } from '../ui/drawer'

type DrawerProps = {
  title?: JSX.Element | string
  content: JSX.Element
}

export function DrawerModal({ title, content }: DrawerProps) {
  return (
    <DrawerContent className='border-none'>
      {title ? <DrawerTitle>{title}</DrawerTitle> : null}
      {content}
    </DrawerContent>
  )
}

import { createPushModal } from 'pushmodal'
import { Drawer } from '../ui/drawer'
import { DrawerModal } from './drawer'
import {
  FullScreenModal,
  FullscreenMobile,
  Modal,
  ResponsiveDialog,
} from './modal'

export const {
  pushModal,
  popModal,
  popAllModals,
  replaceWithModal,
  useOnPushModal,
  onPushModal,
  ModalProvider,
} = createPushModal({
  modals: {
    Modal,
    FullScreenModal,
    FullscreenMobile,
    ResponsiveDialog: {
      Wrapper: Drawer,
      Component: ResponsiveDialog,
    },
    // shadcn drawer needs a custom Wrapper
    Drawer: {
      Wrapper: Drawer,
      Component: DrawerModal,
    },
  },
})

import {
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
} from '@/components/ui/drawer'
import { cn, useIsDesktop } from '@/lib/utils'
import { Icons } from '../icons'

import { Button } from '../ui/button'
import {
  DialogClose,
  DialogContent,
  type DialogContentVariant,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '../ui/dialog'

type PointerDownOutsideEvent = CustomEvent<{
  originalEvent: PointerEvent
}>
type FocusOutsideEvent = CustomEvent<{
  originalEvent: FocusEvent
}>

type ModalProps = {
  title?: JSX.Element | string
  description?: JSX.Element | string
  content?: React.ReactNode
  footer?: React.ReactNode
  dialogClassName?: string
  titleClassName?: string
  contentVariant?: DialogContentVariant
  onInteractOutside?: (
    event: PointerDownOutsideEvent | FocusOutsideEvent,
  ) => void
}

export function Modal({ title, description, content, footer }: ModalProps) {
  return (
    <DialogContent>
      {title ? (
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
          {description ? (
            <DialogDescription>{description}</DialogDescription>
          ) : null}
        </DialogHeader>
      ) : null}

      {content}

      {footer ? <DialogFooter>{footer}</DialogFooter> : null}
    </DialogContent>
  )
}

type FullscreenModalProps = {
  content: React.ReactNode
  title: string
  contentVariant?: DialogContentVariant
  downloadUrl?: string
}

export function FullScreenModal({
  content,
  title,
  contentVariant,
  downloadUrl,
}: FullscreenModalProps) {
  // `DialogContent` requires a `DialogTitle` for the component to be accessible for screen reader users.

  return (
    <DialogContent
      aria-describedby={undefined}
      customClose={
        <DialogClose
          asChild
          aria-label='close'
          className='fixed right-12 top-12 md:right-8 md:top-8 z-50 rounded-sm ring-offset-background transition-opacity focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground'
        >
          <Icons.CircledX className='cursor-pointer' />
        </DialogClose>
      }
      className='max-w-screen h-screen border-none !animate-none p-0'
      variant={contentVariant}
    >
      {downloadUrl ? (
        <a
          href={downloadUrl}
          download={title}
          className='fixed right-24 top-[50px] md:right-20 md:top-[34px] z-50'
        >
          <Icons.CircledDownload className='fill-muted-foreground' />
        </a>
      ) : null}
      <DialogTitle className='sr-only'>{title}</DialogTitle>
      {content}
    </DialogContent>
  )
}

export function ResponsiveDialog({
  title,
  description,
  content,
  footer,
  contentVariant,
  dialogClassName,
  onInteractOutside,
}: ModalProps) {
  const isDesktop = useIsDesktop()

  if (isDesktop) {
    return (
      <DialogContent
        className={cn('sm:max-w-[462px]', dialogClassName)}
        variant={contentVariant}
        aria-describedby={undefined}
        // Doing this to keep clicking outside the modal from closing it.
        // Correct way would be to use AlertDialog I believe, but I could not get it to work with the pushModal library
        onInteractOutside={onInteractOutside ? onInteractOutside : undefined}
      >
        {title ? (
          <DialogHeader>
            <DialogTitle>{title}</DialogTitle>
            {description ? (
              <DialogDescription>{description}</DialogDescription>
            ) : null}
          </DialogHeader>
        ) : (
          <DialogTitle className='hidden'>{title} </DialogTitle>
        )}

        {content}

        {footer ? <DialogFooter>{footer}</DialogFooter> : null}
      </DialogContent>
    )
  }

  return (
    <DrawerContent aria-describedby={undefined}>
      <DrawerHeader className='text-left'>
        {title ? <DrawerTitle>{title}</DrawerTitle> : null}
        {description ? (
          <DrawerDescription>{description}</DrawerDescription>
        ) : null}
      </DrawerHeader>
      <div className='p-4'> {content}</div>
      {footer ? (
        <DrawerFooter className='pt-2'>
          <DrawerClose asChild>
            <Button variant='outline'>Go back</Button>
          </DrawerClose>
        </DrawerFooter>
      ) : null}
    </DrawerContent>
  )
}

export function FullscreenMobile({
  title,
  description,
  content,
  dialogClassName,
  titleClassName,
  contentVariant,
}: ModalProps) {
  return (
    <DialogContent
      aria-describedby={undefined}
      className={cn(
        'max-w-screen h-screen !animate-none p-0 md:max-w-max md:h-auto md:p-6 [&>button]:hidden md:[&>button]:block',
        dialogClassName,
      )}
      variant={contentVariant}
      onOpenAutoFocus={(e) => e.preventDefault()}
    >
      {title ? (
        <DialogHeader className='text-left'>
          <DialogTitle className={titleClassName}>{title}</DialogTitle>
          {description ? (
            <DialogDescription>{description}</DialogDescription>
          ) : null}
        </DialogHeader>
      ) : null}
      {content}
    </DialogContent>
  )
}
